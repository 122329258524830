.immaginis{
 
    width: 100%; 
    height: 50vh;
    object-fit: cover;
}

#container2{
    position: relative;
    text-align: center;
    color: white;
    text-shadow: 2px 2px 4px #000000;
    font-size:x-large;
}

#social{
    display: flex;
    justify-content: flex-end;
}

#claim{
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#sottoClaim{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* css della home vecchia inizio */
#containerModelli ul{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap:nowrap;
    list-style-type: none;
    gap: 20px;
    overflow: auto;
}

.ZoomM{
    width: 80vw;
   
    position:fixed;
    top: 20vh;
    left: 50vw;
    translate: -40vw;
    z-index: 15; 

}
.noZoomM{
    width: 200px;
   
}
/* css della home vecchia fine */