.carrel{
    display: flex;
    justify-content: space-around;
    align-content: space-around;
    row-gap: 20px;
    flex-wrap: wrap;
}
.ant-card-body{
padding: 2px;
}
:where(.css-dev-only-do-not-override-txh9fw).ant-card .ant-card-meta-detail >div:not(:last-child){
margin: 0px 0px;
padding: 0px;
}
.ant-card-meta-detail{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 7px 0px;
}
.bottone{
    margin-bottom: 5px;
}

.btnTorna{
    margin: 10px ;
}
h4{
    margin: 5px;
}
.dividBottom{
    margin: 15px 0px;
}