.Zoom{
    width: 300px;
    
    position:fixed;
    top: 20vh;
    left: 50vw;
    translate: -150px;;
    z-index: 2; 

}
.noZoom{
    width: 150px;
}
