
#containerCard ul{
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
    gap: 20px;
    list-style:none;
    padding: 0px;
    
}
#containerCard{
     padding: 0px;
    
    
    width: 100vw;
}
#menu2{
 
   
    position: sticky;
    top: 55px;
    z-index: 3;
    background-color: #ffffff;
  
    padding: 10px;
 
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.1);
}
.divider{
    margin: 10px 0px;
    position: fixed;
    top: 97px;
    z-index: 4;
}
#containerCard{
  margin-top: 18px;

}