#parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
}
h1{
  margin: 10px 0px 0px 0px;
}
#child1 {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: center;
}
#para {
  width: 200px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 20px;
}
#contForm {
  width: 200px;
  margin: 20px;
  flex-grow: 1;
 padding: 5px;
  
}
#formio{
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    box-shadow: 1px 1px 11px rgba(107, 107, 107, 0.4);
    padding: 20px;
}
#child1 Button{
    margin-top: 10px;
}
#child2 {
  display: flex;
  justify-content:space-evenly;
  flex-flow: row wrap;
  align-items:center;
  background-color:#d32f2f; 
  padding: 20px;
  color: whitesmoke;
}
#orari {
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color:#d32f2f; 
  flex-grow: 1;
  padding: 20px;
}
.ant-card-head-title{
  color: whitesmoke;
}
#orari p{
  margin: 0px;
  text-align: justify;
}

#child2 a{
  color: aliceblue;
  text-decoration: none;
}
#chiama{
  padding: 20px;
  flex-grow: 1;
  background-color:#d32f2f;
}
#chiama p{
  margin: 7px;
}
#mappa {
  padding: 20px;
  flex-grow: 2;
  background-color:#d32f2f; 
}