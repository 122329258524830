#register{
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    box-shadow: 1px 1px 11px rgba(107, 107, 107, 0.4);
    padding: 33px;
    margin: 33px;
    /* display: flex;
    align-content: center;
    flex-direction: column; */
}
#cancella{
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    box-shadow: 1px 1px 11px rgba(107, 107, 107, 0.4);
    padding: 33px;
    margin: 33px;
}
#container{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

}
#para{
    background-color:#d32f2f;
    border-radius: 5px;
    box-shadow: 1px 1px 11px rgba(107, 107, 107, 0.4);
    margin: 33px;
    padding: 33px;
}
p{
    /* color: rgb(105, 103, 103); */
    color: rgba(255, 255, 255, 0.915);
    text-align: justify;
    text-shadow: none;

}


